import { CONTRACTS } from '@alexgo-io/alex.js';
import { ORDER_TYPES } from './OrderVO';

export function getContractTokenName(v) {
  const key = v.toLowerCase();
  let result = key;
  switch (key) {
    case 'btc':
    case 'wbtc':
      result = 'wbtc';
      break;
    case 'usda':
    case 'usdc':
      result = 'usda';
      break;
    case 'stx':
      result = 'stx';
      break;
  }
  return result;
}
export function tokenDName(token, lowerCase = false){
  if(token === undefined) return '';
  const uToken = token.toUpperCase()
  if(uToken === 'BTC' || uToken === 'WBTC'){
    if(lowerCase) return 'xbtc'
    return 'xBTC';
  }
  if(uToken === 'WSTX'){
    if(lowerCase) return 'stx'
    return 'STX';
  }
  const otherToken = uToken.split('WBTC').join('xBTC');
  if(lowerCase){
    return otherToken.toLowerCase()
  }
  return otherToken;
}
export function tokenCaName(token){
  if(token === undefined) return '';
  const uToken = token.toUpperCase()
  if(uToken === 'XBTC' || uToken === 'WBTC'){
    return 'wbtc';
  }
  if(uToken === 'WSTX' || uToken === 'STX'){
    return 'wstx';
  }
  return uToken.toLowerCase();
}
export function getPriceTokenName(v) {
  const key = v.toLowerCase();
  let result = '';
  switch (key) {
    case 'btc':
    case 'wbtc':
      result = 'WBTC';
      break;
    case 'usda':
    case 'usdc':
      result = 'USDA';
      break;
    case 'stx':
      result = 'STX';
      break;
  }
  return result;
}
export function getTokenContractName(token) {
  return `token-${getContractTokenName(token)}`;
}
export function getYokenContractName(token, expiry) {
  return `yield-${getContractTokenName(token)}-${expiry}`;
}
export function getKTokenContractName(from, to, expiry) {
  return `key-${getContractTokenName(from)}-${expiry}-${getContractTokenName(
    to
  )}`;
}
export const createMarginPattern = (marginVO) => {
  let result = {};
  if (marginVO.term === 'long') {
    result = {
      token1: getTokenContractName('wbtc'),
      token2: getTokenContractName('usda'),
      token3: getTokenContractName('usda'),
      token4: getTokenContractName('wbtc'),
      yToken1: getYokenContractName('usda', marginVO.expiry),
      yTokenForm: 'yield-usda',
      expiry: marginVO.expiry,
      tokenAName: getContractTokenName(marginVO.toToken).toUpperCase(),
      tokenBName: getContractTokenName(marginVO.fromToken).toUpperCase(),
      flashLoanContract: `flash-loan-user-margin-wbtc-usda`,//TODO: need change back after next reboot
      flashLoanToken: 'token-wbtc',
    };
  } else {
    result = {
      token1: getTokenContractName('wbtc'),
      token2: getTokenContractName('usda'),
      token3: getTokenContractName('wbtc'),
      token4: getTokenContractName('usda'),
      yToken1: getYokenContractName('wbtc', marginVO.expiry),
      yTokenForm: 'yield-wbtc',
      expiry: marginVO.expiry,
      tokenAName: getContractTokenName(marginVO.toToken).toUpperCase(),
      tokenBName: getContractTokenName(marginVO.fromToken).toUpperCase(),
      flashLoanContract: `flash-loan-user-margin-usda-wbtc`,//TODO: need change back after next reboot
      flashLoanToken: 'token-usda',
    };
  }
  return result;
};
export const createDBPattern = (orderVO) => {
  console.log('createDBPattern', orderVO);
  if (orderVO.type === ORDER_TYPES.BORROW) {
    if (
      orderVO.tokenType === 'WBTC' &&
      (orderVO.collateralType === 'USDA' || orderVO.toToken === 'USDA')
    ) {
      return {
        tokenA: getTokenContractName('wbtc'),
        tokenB: getTokenContractName('usda'),
        yTokenA: getYokenContractName('wbtc', orderVO.expiry),
        yTokenB: getYokenContractName('usda', orderVO.expiry),
        keyToken: getKTokenContractName('wbtc', 'usda', orderVO.expiry),
        expiry: orderVO.expiry,
        tokenAName: 'WBTC',
        tokenBName: 'USDA',
      };
    } else {
      return {
        tokenA: getTokenContractName('usda'),
        tokenB: getTokenContractName('wbtc'),
        yTokenA: getYokenContractName('usda', orderVO.expiry),
        yTokenB: getYokenContractName('wbtc', orderVO.expiry),
        keyToken: getKTokenContractName('usda', 'wbtc', orderVO.expiry),
        expiry: orderVO.expiry,
        tokenAName: 'USDA',
        tokenBName: 'WBTC',
      };
    }
  } else {
    if (
      orderVO.tokenType === 'WBTC' &&
      (orderVO.collateralType === 'USDA' || orderVO.toToken === 'USDA')
    ) {
      return {
        tokenA: getTokenContractName('wbtc'),
        tokenB: getTokenContractName('usda'),
        yTokenA: getYokenContractName('wbtc', orderVO.expiry),
        yTokenB: getYokenContractName('usda', orderVO.expiry),
        keyToken: getKTokenContractName('wbtc', 'usda', orderVO.expiry),
        expiry: orderVO.expiry,
        tokenAName: 'WBTC',
        tokenBName: 'USDA',
      };
    } else {
      return {
        tokenA: getTokenContractName('usda'),
        tokenB: getTokenContractName('wbtc'),
        yTokenA: getYokenContractName('usda', orderVO.expiry),
        yTokenB: getYokenContractName('wbtc', orderVO.expiry),
        keyToken: getKTokenContractName('usda', 'wbtc', orderVO.expiry),
        expiry: orderVO.expiry,
        tokenAName: 'USDA',
        tokenBName: 'WBTC',
      };
    }
  }
};
