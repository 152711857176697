import { StakeForm } from '../../template/StakeForm';
import actionTypes from './stake.types';
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      const newVO = StakeForm.cloneVO({
        ...state,
        ...action.payload,
      });
      return newVO;
    default:
      return state;
  }
};
export default reducer;