import actionTypes from './account.types';
export const INIT_VALUE = {
  stxAddress: '',
};

const accountReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_STX_ADDRESS:
      return {
        ...state,
        stxAddress: action.payload,
      };
    default:
      return state;
  }
};

export default accountReducer;
