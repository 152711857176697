import { checkStatusApi } from "../components/faucet/utils";
import { API_HOST } from "./base";

export const TX_RESULT_CODE = {
  WAITING: 0,
  SUCCESS: 1,
  ALREADY_GOT: 2,
  OTHER: 3,
  RUNNING: 4,
  TIME_OUT: 5,
  BAD_EXEC: 6,
  NOT_RECAP: 7,
};
const sleep = async (duration = 5000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

const parseResault = (data) => {
  const status = data.tx_status;
  let result = data.tx_result.repr;
  let callParams = data.contract_call.function_args[0].repr;
  result = result.slice(10, result.length - 2);
  result = result
    .split('(')
    .join('')
    .split(')')
    .join('')
    .split('err')
    .join('')
    .split('ok')
    .join('');
  const resultArr = result.split('  ').map((r) => {
    const data = r.trim();
    return data === 'true';
  });
  callParams = callParams.slice(6, callParams.length - 1);
  const callParamsArr = callParams.split(' ');
  return callParamsArr.map((cp, index) => {
    return {
      address: cp,
      result: resultArr[index],
    };
  });
};

export const keepTracking = async (txId) => {
  console.log('Checking tx status...', txId);
  const tracingURL = `${API_HOST}tx/0x${txId}`;
  let result = null;
  while (true) {
    const response = await fetch(tracingURL);
    result = await response.json();
    console.log('tx status', result.tx_status);
    if (result.tx_status !== 'pending') {
      break;
    }
    await sleep(5000);
  }
  return result.tx_status === 'success';
};
export const keepTrackingRaw = async (txId) => {
  console.log('Checking tx status...', txId);
  const tracingURL = `${API_HOST}tx/0x${txId}`;
  let result = null;
  while (true) {
    const response = await fetch(tracingURL);
    result = await response.json();
    console.log('tx status', result.tx_status);
    if (result.tx_status !== 'pending') {
      break;
    }
    await sleep(5000);
  }
  return result;
};
export const keepTrackingGroupedTX = async (txId, address) => {
  const tracingURL = `${API_HOST}tx/0x${txId}`;
  console.log('Checking tx status...', txId, tracingURL);
  let result = null;
  while (true) {
    const response = await fetch(tracingURL);
    result = await response.json();
    console.log('tx status', result.tx_status);
    if (result.tx_status !== 'pending') {
      break;
    }
    await sleep(5000);
  }
  if (result.tx_status === 'success') {
    const resultArr = parseResault(result);
    const target = resultArr.find((r) => {
      return r.address === address;
    });
    console.log('keepTrackingGroupedTX',target);
    return {
      success: target.result,
      code: target.result ? TX_RESULT_CODE.SUCCESS : TX_RESULT_CODE.ALREADY_GOT,
    };
  }
  return {
    success: false,
    code: TX_RESULT_CODE.OTHER,
  };
};

export const keepTrackingQueue = async (accountId) => {
  console.log('Checking queue status...', accountId);
  const tracingURL = `${checkStatusApi}?account=${accountId}`;
  let result = null;
  while (true) {
    const response = await fetch(tracingURL);
    result = await response.json();
    console.log('tx status', result);
    if (
      result.code !== TX_RESULT_CODE.WAITING &&
      result.code !== TX_RESULT_CODE.RUNNING
    ) {
      break;
    }
    await sleep(5000);
  }
  return result;
};
