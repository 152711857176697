import { TX_STATUS } from '@alexgo-io/alex.js';
import { makeObservable, observable, runInAction } from 'mobx';
import { updateTX } from '../../stacks/exchange-trade';
export class HistoryTXModuleStore {
  txid = '';
  account = '';
  date = 0;
  status = '';
  amount = 0;
  message = '';
  constructor(txObj) {
    makeObservable(this, {
      txid: observable,
      account: observable,
      date: observable,
      status: observable,
      amount: observable,
      message: observable,
    });
    for (const key in txObj) {
      this[key] = txObj[key];
    }
  }
  updateStatus(v) {
    runInAction(() => {
      this.status = v;
      updateTX(this.txid, v);
    });
  }

  updateFromRawData(data) {
    runInAction(() => {
      this.parseData(data);
      updateTX(this.txid, this.status);
    });
  }
  parseData(data) {
    if (data.tx_status === TX_STATUS.PENDING) {
      return false;
    }
    if (data.tx_status !== this.status) {
      if (
        [
          TX_STATUS.ABORT,
          TX_STATUS.SUCCESS,
          TX_STATUS.ABORT_POST_CONDITION,
        ].indexOf(data.tx_status) !== -1
      ) {
        this.status = data.tx_status;
      } else {
        this.status = TX_STATUS.OTHER_ERROR;
      }
    }
  }
  removeOK(value) {
    return value.replace('(ok', '').replace(')', '').replace(' ', '');
  }
}
