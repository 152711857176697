/**
 * This is a context API wrap, focus on stack user auth data.
 */
import { createContext, useState, useContext } from 'react';
import { userSession } from './stack-auth';
const AuthContext = createContext(undefined);

/**
 * Use this AuthProvider wrap a root component like
 * <AuthProvider>
 *  <TheComponent/>
 * </AuthProvider>
 * Then, visit data via useAuth hook
 * @param {*} children the children element 
 * @returns 
 */
export const AuthProvider = ({ children }) => {
  const [signedIn, setSignedIn] = useState(userSession.isUserSignedIn());
  // Get user data for the initialization 
  let initUserData = null
  if (signedIn && userSession.isUserSignedIn()) {
    initUserData = userSession.loadUserData()
  }
  const [userData, setUserData] = useState(initUserData);
  const update = (key, value) => {
    if (key === 'userData') {
      setUserData(value);
    } else {
      setSignedIn(value);
    }
  };
  //Package a hook as the value of Provider
  const data = [{ signedIn, userData }, update];
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};
/**
 * useAuth is a hook for visit and manipulate data AuthProvider range
 * const [{signedIn, userData}, update] = useAuth()
 * Then use  signedIn or userData as you wish, update function receive two parameters , key and the state you want to update.
 * update('signedIn', false)
 * @returns 
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth can only be used inside AuthProvider');
  }
  return context;
};
