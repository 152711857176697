import { TOKEN_DECIMAL_PLACES, SLIPPAGE_DECIMAL_PLACES } from './constants';
import { versions, c32addressDecode, c32address } from 'c32check';
const VALIID_INPUT_KEY = /[0-9]|\./;

export function addrStr(str, longerThan = 8) {
  if (str.length > longerThan) {
    return `${str.substr(0, 4)}....${str.substr(str.length - 4)}`;
  }
  return str;
}
export function cap0(str) {
  if (str === undefined) {
    return '';
  }
  const arr = [...str];
  arr[0] = arr[0].toUpperCase();
  return arr.join('');
}

export function getTokenNameByWalletName(n) {
  if (n.includes('wbtc')) {
    return 'WBTC';
  }
  return n;
}

export function formatAmountInput(input, decimalPlaces = TOKEN_DECIMAL_PLACES) {
  let strValue = input
    .replace(/^\./, '0.')
    .replace(/^0+/, '0')
    .replace(/^0+([1-9])/, '$1');
  if (strValue.indexOf('.') != -1) {
    const endPosition = strValue.indexOf('.') + decimalPlaces + 1;
    strValue = strValue.substring(0, endPosition);
  }
  return strValue;
}

export function formatSlippageValue(value) {
  return parseFloat(Number(value * 100).toFixed(SLIPPAGE_DECIMAL_PLACES));
}

export function onInputKeyPressed(event) {
  if (!VALIID_INPUT_KEY.test(event.key)) {
    event.preventDefault();
  }
}

export function testAddressToMain(address) {
  // if(address.length !== 41) return '';
  const [version, hash160] = c32addressDecode(address);
  let singlesig = true;
  switch (version) {
    case versions.mainnet.p2pkh:
      break;
    case versions.mainnet.p2sh:
      singlesig = false;
      break;
    case versions.testnet.p2pkh:
      break;
    case versions.testnet.p2sh:
      singlesig = false;
      break;
  }
  const type = singlesig ? 'p2pkh' : 'p2sh';
  return c32address(versions.mainnet[type], hash160)
}
