import { createContext, useContext } from 'react';
import { ExchangeStore } from './ExchangeStore';

export const ExchangeContext = createContext();

const ExchangeProvider = ({ children }) => {
  const store = new ExchangeStore();
  return (
    <ExchangeContext.Provider value={{ store }}>
      {children}
    </ExchangeContext.Provider>
  );
};
export default ExchangeProvider;

export const useExchangeStore = () => {
  const store = useContext(ExchangeContext);
  if (store == null) {
    throw new Error(`useSpotStore must be used within a ExchangeProvider`);
  }
  return store;
};
