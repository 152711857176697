import { useConnect } from '@stacks/connect-react';
import styles from './nav.module.css';
const ConnectWalletButton = ({size = 'small'}) => {
  const { doOpenAuth } = useConnect();

  function signIn() {
    doOpenAuth();
  }
  const getButtonStyle = ()=>{
    if(size === 'small'){
      return styles.connectToWalletButton;
    }
    if(size === 'large'){
      return styles.connectToWalletButtonLarge;
    }
    return styles.connectToWalletButtonFull
  }

  return (
    <div className={getButtonStyle()} onClick={signIn}>
      CONNECT WALLET
    </div>
  );
};
export default ConnectWalletButton;
