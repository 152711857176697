import { CONTRACTS } from '@alexgo-io/alex.js';
import { LAPLACE_API } from './base';

export const CYCLE_BLOCKS = 525;
export const STAKE_APR_FACTOR = 4000;
// const TARGET_TOKEN = 'token-alex';
const TARGET_TOKEN = 'token-t-alex';

// export const TARGET_TOKEN_WALLET_NAME = 'alex';
export const TARGET_TOKEN_WALLET_NAME = 't-alex';
let cachedCCB = null;
const cachedRC = {};
const cachedSB = {};
export const getCurrentBlockHeight = async () => {
  if (cachedCCB !== null) return cachedCCB;
  const result = await global.alexjs.getBlockInfo();
  cachedCCB = result.blockHeight;
  return result.blockHeight;
};
export const getRewardCycle = async (cb) => {
  if (cachedRC[cb] !== undefined) return cachedRC[cb];
  const arp = global.alexjs.getContract(CONTRACTS.ARP);
  const result = await arp.getRewardCycle(TARGET_TOKEN, cb);
  cachedRC[cb] = result.getBN().toNumber();
  return result.getBN().toNumber();
};
export const getStartBlock = async (nextCycle) => {
  if (cachedSB[nextCycle] !== undefined) return cachedSB[nextCycle];
  const arp = global.alexjs.getContract(CONTRACTS.ARP);
  const result = await arp.getFirstStacksBlockInRewardCycle(
    TARGET_TOKEN,
    nextCycle
  );
  cachedSB[nextCycle] = result.getBN().toNumber();
  return result.getBN().toNumber();
};

export const getStakedList = async (from, to) => {
  const ash = global.alexjs.getContract(CONTRACTS.StakingHelper);
  const paramList = Array.from(Array(to).keys()).slice(from);
  const result = await ash.getStaked(TARGET_TOKEN, paramList);
  const resultList = result.list.map((e) => {
    return {
      'amount-staked': e['amount-staked'].value.toNumber() / 1e8,
      'to-return': e['to-return'].value.toNumber() / 1e8,
    };
  });
  return resultList;
};

export const getRewards = async (from, to) => {
  const ash = global.alexjs.getContract(CONTRACTS.StakingHelper);
  const paramList = Array.from(Array(to).keys()).slice(from);
  const result = await ash.getStakingRewards(TARGET_TOKEN, paramList);
  console.log('getRewards', result);
  const resultList = result.list.map((e) => {
    return e.toNumber() / 1e8;
  });
  return resultList;
};

export const getStakedBlocks = async (from, to) => {
  const arp = global.alexjs.getContract(CONTRACTS.ARP);
  const result = await arp.getFirstStacksBlockInRewardCycle(TARGET_TOKEN, from);
  const startFrom = result.getBN().toNumber();
  const list = [];
  for (let index = from; index < to; index++) {
    list.push(startFrom + CYCLE_BLOCKS * index);
  }
  return list;
};

export const runStake = async (stakeForm, balances) => {
  const arp = global.alexjs.getContract(CONTRACTS.ARP);
  let max = Number(balances['t-alex']) * 1e8
  let amoutTo = Math.ceil(stakeForm.amount * 1e8);
  if(amoutTo > max){
    amoutTo = max;
  }
  console.log('runStake', amoutTo, max);
  
  const result = await arp.stakeTokens(
    TARGET_TOKEN,
    amoutTo,
    stakeForm.cycleCount
  );
  return result;
};
export const runUnStake = async (targetCycles) => {
  const ash = global.alexjs.getContract(CONTRACTS.StakingHelper);
  const result = await ash.claimStakingReward(TARGET_TOKEN, targetCycles);
  return result;
};
export const getEarningChartData = async (currentCycle) => {
  try {
    const response = await fetch(
      `${LAPLACE_API}dynamic/alex_reserve_pool/data/latest?token=${TARGET_TOKEN}&reward_cycle=${currentCycle}&offset=0&limit=32`
    );
    const data = await response.json();
    const list = data['reserveLatestDetails'];
    const resultArray = [];
    let last = 0;
    for (let index = 0; index < list.length; index++) {
      const rawData = list[index];
      let rawReturn =
        rawData['values']['coinbase_amount'] /
        rawData['values']['staking_stats'];
      rawReturn = isFinite(rawReturn) ? rawReturn : 0;
      let v = index == 0 ? rawReturn : (last * index + rawReturn) / (index + 1);
      v *= (STAKE_APR_FACTOR * (index + 1)) / (index + 2);
      resultArray.push(v);
      last = rawReturn;
    }
    return resultArray;
  } catch (err) {
    return [];
  }
};

export const getEarningChartDataV2 = async () => {
  try {
    const response = await fetch(
      `${LAPLACE_API}staking/get_staking_apy?token=${TARGET_TOKEN}`
    );
    const data = await response.json();
    console.log('getEarningChartDataV2', data);
    let list = [];
    for (const key in data['output']) {
      if (Object.hasOwnProperty.call(data['output'], key)) {
        const element = Number(data['output'][key]) * 100;
        list.push(Number(element));
      }
    }
    return list;
  } catch (err) {
    return [];
  }
};

//989 -> 990
//(ok (list (err u1) (ok (tuple (entitled-token u183150000) (to-return u0)))))

// TX repr: (ok (list (err u1)))
//990 -> 1011
// TX repr: (ok (list (ok (tuple (entitled-token u2112676000) (to-return u0)))))

// (ok (list (err u10017) (err u10017) (err u10017) (err u10017) (err u10017)))
