/**
 * This is a context API wrap, focus on stack user auth data.
 */
import { createContext, useState, useContext } from 'react';
export const GlobalStatusContext = createContext(undefined);

export let cacheData = {
  balances: {
    WBTC: '0.0',
    STX: '0.0',
    USDA: '0.0',
  },
  otherFTBalances: {},
  marginFTBalances: {},
  borrowTokens: [],
  depositTokens: [],
  subPageStack: [],
  currentTX: null,
  topBarEventCount: 0
};

export const GlobalStatusProvider = ({ children }) => {
  const [vars, setVars] = useState(cacheData);
  const update = (value, from) => {
    // console.log('gvup:', value, from);
    setVars(value);
    cacheData = value;
  };
  const data = [vars, update];
  return (
    <GlobalStatusContext.Provider value={data}>
      {children}
    </GlobalStatusContext.Provider>
  );
};

export const useGlobalVars = () => {
  const context = useContext(GlobalStatusContext);
  if (context === undefined) {
    throw new Error('useAuth can only be used inside TXStatusProvider');
  }
  return context;
};
