import { TX_STATUS } from '@alexgo-io/alex.js';
import { v4 } from 'uuid';
import { EXPIRIES } from '../stacks/contracts-data';
import { EST_STX_COST } from './estimate-stx-cost';
export const ORDER_TYPES = {
  BORROW: 'borrow',
  DEPOSIT: 'deposit',
};
export class OrderVO {
  constructor(data) {
    if(data === undefined) return;
    this.id = data.id || v4();
    this.createdDate = data.createdDate
      ? new Date(data.createdDate)
      : new Date();
    this.type = data.type || '';
    
    if(data.maturityDate === undefined){
      this.maturityDate = new Date();
    }else{
      if(data.maturityDate instanceof Date){
        this.maturityDate = data.maturityDate
      }else{
        this.maturityDate = new Date(data.maturityDate)
      }
    }
    this.tokenType = data.tokenType || '';
    this.apy = data.apy || '0.0';
    
    if (this.type === ORDER_TYPES.BORROW) {
      this.collateralType = data.collateralType || '';
      this.collateral = data.collateral || '0.0';
      this.cost = 0//EST_STX_COST['borrow']
    }else{
      this.cost = 0//EST_STX_COST['deposit']
    } 
    this.interest = data.interest || '0.0';
    this.txId = data.txId || '';
    // belong to tx info
    this.txStatus = data.txStatus || TX_STATUS.PENDING;
    this.txvo = data.txvo || {};
    this.fromToken = this.tokenType;
    this.toToken = (this.tokenType == 'WBTC' ? 'USDA' : 'WBTC');
    this.expiry = EXPIRIES[0];
    // this.mtu = 0.01
    if(this.type === ORDER_TYPES.BORROW){
      this.mtu = this.tokenType === 'WBTC' ? 0.001 : 1
    }else{
      this.mtu = this.tokenType === 'WBTC' ? 0.01 : 100
    }
    this.amount = data.amount || '';
    this.ltv = 0;
    this.slippage = 0.005;
    this.slippageAmount = 0;
    this.liquidityProviderFee = 0;
  }
  resetMTU(){
    if(this.type === ORDER_TYPES.BORROW){
      this.mtu = this.tokenType === 'WBTC' ? 0.001 : 1
    }else{
      this.mtu = this.tokenType === 'WBTC' ? 0.01 : 100
    }
  }
  static create(data) {
    const addOn = {
      ...data,
      createdDate: new Date(),
      id: v4(),
    };

    return new OrderVO(addOn);
  }
  static cloneVO(data, from) {
    const vo = new OrderVO();
    for (const key in data) {
      vo[key] = data[key];
    }
    console.log('OrderVO clone',vo, data,from);
    return vo;
  }
}
