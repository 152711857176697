function capitalize(token) {
  const result = token.replace(/\busda\b|\bwbtc\b/g, (s) => s.toUpperCase());
  return result;
}

export const parseTokens = (token, scaling, balance, capitalization = true) => {
  const parsedBalance = balance / scaling;
  const parsedToken = capitalization ? capitalize(token) : token;

  return {
    parsedBalance,
    parsedToken,
  };
};
