/* eslint-disable @next/next/no-sync-scripts */
import TopNavBar from '../nav/TopNavBar';
import StatusBar from '../nav/StatusBar';
import styles from './common.module.css';
import UnSupportAlert from './UnSupportAlert';
import { isStacksWalletInstalled } from '@stacks/connect';
import { useState } from 'react';
import AccountProvider from '../../context/Account/account.provider';
import StakeProvider from '../../context/Stake/stake.provider';
import { useRouter } from 'next/router';
import BugBounty from './BugBounty';
import ExchangeProvider from '../../context/Exchange/exchange.provider';
const Layout = ({ children }) => {
  const [bSupport, setBSupport] = useState(true);
  const [wSupport, setWSupport] = useState(true);
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      setBSupport(window.chrome !== undefined);
      setWSupport(isStacksWalletInstalled());
    }, 1000);
  }
  let type = 'broswer';
  if (!wSupport) {
    type = 'wallet';
  }
  const currentRoute = useRouter();
  return (
    <div className={`${styles.layout} pt-24`}>
      <AccountProvider>
        {/* <StatusBar /> */}
        {currentRoute.route === '/stake' ? (
          <StakeProvider>{children}</StakeProvider>
        ) : currentRoute.route === '/exchange' ? (
          <ExchangeProvider>{children}</ExchangeProvider>
        ) : (
          children
        )}
        {/* <TopNavBar /> */}
        {/* <BugBounty /> */}
      </AccountProvider>
      {(!bSupport || !wSupport) && <UnSupportAlert type={type} />}
    </div>
  );
};
export default Layout;
