import { WATCHER_EVENTS } from '@alexgo-io/alex.js';
import { EVENT_TYPES } from '@alexgo-io/alex.js/dist/AlexJSEnum';
import { useContext, useEffect } from 'react';
import { useAuth } from '../../context/auth-context';
import { useGlobalVars, cacheData } from '../../context/global-context';
import { userSession } from '../../context/stack-auth';
import { updateTXStatus } from '../../stacks/order-data';
import { updateBalances } from '../../stacks/contracts-data';
import { setAccountAddress } from '../../stacks/account';
import { AccountContext } from '../../context/Account/account.provider';

const consumedTX = {};

const TXStatusWatcher = () => {
  const [{ userData }] = useAuth();
  const [gv, update] = useGlobalVars();
  const {stxAddress , updateSTXAddress} = useContext(AccountContext);
  const updateTXVO = async (txvo) => {
    if (consumedTX[txvo.txId] !== undefined) return;
    consumedTX[txvo.txId] = txvo;
    const newGV = JSON.parse(JSON.stringify(cacheData));
    newGV.currentTX = txvo;
    delete newGV.currentTX['sendRawData'] 
    await updateTXStatus(txvo);
    update(newGV, 1);
  };
  const onTXWatcherSuccess = (event) => {
    updateTXVO(event.detail);
  };
  const onTXWatcherFail = (event) => {
    updateTXVO(event.detail);
  };
  useEffect(() => {
    if (userData) {
      const timer = setInterval(() => {
        if (global.alexjs) {
          clearInterval(timer);
          global.alexjs.onEvent(
            EVENT_TYPES.WATCHER,
            WATCHER_EVENTS.SUCCESS,
            onTXWatcherSuccess
          );
          global.alexjs.onEvent(
            EVENT_TYPES.WATCHER,
            WATCHER_EVENTS.FAIL,
            onTXWatcherFail
          );
          global.alexjs.setUserSession(userSession);
          
          const stxAddress = global.alexjs.getSTXAddress(userSession);
          updateSTXAddress(stxAddress);
          setAccountAddress(stxAddress);
        }
      }, 200);
    }
    return () => {
      if (global.alexjs) {
        global.alexjs.offEvent(
          EVENT_TYPES.WATCHER,
          WATCHER_EVENTS.SUCCESS,
          onTXWatcherSuccess
        );
        global.alexjs.offEvent(
          EVENT_TYPES.WATCHER,
          WATCHER_EVENTS.FAIL,
          onTXWatcherFail
        );
      }
    };
  }, [gv, update, userData]);
  useEffect(() => {
    const runner = async () => {
      if (stxAddress !== '') {
        const {
          newBalances,
          marginPositionBalances,
          borrowTokens,
          depositTokens,
        } = await updateBalances(stxAddress);
        const newGV = JSON.parse(JSON.stringify(gv));
        newGV.balances = newBalances;
        newGV.marginFTBalances = marginPositionBalances;
        newGV.borrowTokens = borrowTokens;
        newGV.depositTokens = depositTokens;
        console.log('updateBalances', newGV);
        update(newGV, 3);
      }
    };
    runner();
  }, [stxAddress]);
  return <div></div>;
};
export default TXStatusWatcher;
