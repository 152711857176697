import { createContext, useReducer } from 'react';
import accountReducer, { INIT_VALUE } from './account.reducer';
import actionTypes from './account.types';
export const AccountContext = createContext({
  ...INIT_VALUE,
});

const AccountProvider = ({ children }) => {
  const [store, dispatch] = useReducer(accountReducer, INIT_VALUE);
  const { stxAddress } = store;
  const updateSTXAddress = (newAddress) => {
    dispatch({
      type: actionTypes.UPDATE_STX_ADDRESS,
      payload: newAddress,
    });
  };
  return (
    <AccountContext.Provider
      value={{
        stxAddress,
        updateSTXAddress
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
export default AccountProvider;
