import { makeObservable, observable } from "mobx"
export class ButtonModuleStore {
  parentStore = null
  constructor(exchangeStore) {
    this.parentStore = exchangeStore
    makeObservable(this, {})
  }
  get buttonType() {
    if (!this.parentStore.isWalletConneted) {
      return 'connetWallet';
    }
    if (this.parentStore.contractRunning) {
      return 'loading';
    }
    if (this.parentStore.balance === 0) {
      return 'accountError';
    }
    return 'able';
  }
}
