import { API_HOST, jfecth } from './base';
export async function getBalance(stxAddress) {
  const url = `${API_HOST}address/${stxAddress}/stx`;
  return jfecth(url);
}

export async function getBalances(stxAddress) {
  const url = `${API_HOST}address/${stxAddress}/balances`;
  return jfecth(url);
}
let accountAddress = ''
export async function setAccountAddress(address) {
  accountAddress = address;
}
export async function postUserLog(data) {
  const url = `https://faucet.alexgo.io/log`;
  const params = { user:accountAddress, ...data }
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
}
