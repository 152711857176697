import Icon from '@ant-design/icons';
const SVG = () => (
  <svg
    width='13'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{
      width: '14px',
      height: '14px',
    }}
  >
    <path
      d='M14 8.66666V15.3333H0V2.66666H8V3.99999H1.33333V14H12.6667V8.66666H14ZM16 0.666656H8.67467L11.3647 3.33332L6.71333 8.04666L8.59867 9.93199L13.25 5.21866L16 7.99999V0.666656Z'
      fill='#C4C4C4'
    />
  </svg>
);

export const ExplorerIcon = (props) => {
  return <Icon component={SVG} {...props} />;
};
