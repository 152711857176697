import Icon from '@ant-design/icons';
const DBSVG = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.2045 9.483L12.759 12.9855L15.165 12.0165C17.7691 10.9675 20.7408 12.1147 21.9645 14.6415L22.083 14.907L22.485 15.867L10.4475 21.045H1.5V18.945H10.0155L19.5885 14.826C18.7435 13.8685 17.4148 13.4966 16.1955 13.8765L15.9495 13.9665L9.9975 16.362H6.723V14.262H9.591L10.3575 13.953L6.5985 11.583H1.5V9.483H7.2045ZM16.3575 6.984V9H8.625V6.984H16.3575ZM16.3575 3V5.016H8.625V3H16.3575Z'
      fill='#C4C4C4'
    />
  </svg>
);

export const DepositBorrowIcon = (props) => <Icon component={DBSVG} {...props} />;