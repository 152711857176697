import { makeObservable, observable, runInAction } from "mobx"
import {
  loadMyTx,
  runExchange,
  saveTx,
  updateTX,
} from "../../stacks/exchange-trade"
import { ButtonModuleStore } from "./ButtonModuleStore"
import { keepTrackingRaw } from "../../stacks/transaction"
import { HistoryTXModuleStore } from "./HistoryTXModuleStore"
const EXPIRY_TIME = 3600 * 1000
export class ExchangeStore {
  testnetAddress = ""
  mainnetAddress = ""
  inputtedAddress = ""
  timer = 0
  balance = 0
  isWalletConneted = false
  contractRunning = false
  buttonModule = null
  hasHistory = true
  txModule = null
  showTXAlert = false
  pendingContract = false
  constructor(storage) {
    makeObservable(this, {
      testnetAddress: observable,
      mainnetAddress: observable,
      timer: observable,
      balance: observable,
      balance: observable,
      isWalletConneted: observable,
      contractRunning: observable,
      inputtedAddress: observable,
      hasHistory: observable,
      showTXAlert: observable,
    })
    this.buttonModule = new ButtonModuleStore(this)
    this.storage = storage
  }
  get isValidAddressStart() {
    return this.inputtedAddress.startsWith("SP")
  }
  get isValidAddressLength() {
    return true
  }
  get compoundInputValid() {
    if (this.inputtedAddress === "") {
      return "disable"
    }
    if (!this.isValidAddressStart) {
      return "addressError"
    }
    if (!this.isValidAddressLength) {
      return "addressLengthError"
    }
    return "able"
  }
  get txStatusObj() {
    if (this.txModule) {
      return {
        txStatus: this.txModule.status,
        txvo: {
          message: "",
          txId: this.txModule.txid,
        },
      }
    } else {
      return {
        txStatus: "pending",
        txvo: {
          message: "",
        },
      }
    }
  }
  get lastTransationUrl() {
    if (this.hasHistory) {
      return `https://explorer.alexgo.io/txid/${this.txModule.txid}?chain=mainnet`
    } else {
      return ""
    }
  }

  increase() {
    this.timer += 1
  }
  setAddresses(mainnet, testnet) {
    runInAction(() => {
      this.mainnetAddress = mainnet
      this.testnetAddress = testnet
    })
  }
  setBalance(balances) {
    runInAction(() => {
      if (balances.allTokens["t-alex"]) {
        this.balance = balances.allTokens["t-alex"]
      } else {
        this.balance = 0
      }
    })
  }
  setIsWalletConnected(v) {
    runInAction(() => {
      console.log("setIsWalletConnected", v)
      this.isWalletConneted = v
    })
  }

  setIsWalletConnected(v) {
    runInAction(() => {
      this.isWalletConneted = v
    })
  }

  setInputtedAddress(v) {
    runInAction(() => {
      this.inputtedAddress = v
    })
  }
  setContractRunning(v) {
    runInAction(() => {
      this.contractRunning = v
    })
  }
  setHasHistory(v) {
    runInAction(() => {
      this.hasHistory = v
    })
  }
  setShowTXAlert(v) {
    runInAction(() => {
      this.showTXAlert = v
    })
  }

  afterGotHistory(tx) {
    runInAction(() => {
      const now = Date.now()
      this.hasHistory = tx !== undefined
      if (tx !== undefined) {
        this.txModule = new HistoryTXModuleStore(tx)
      }
      if (tx !== undefined && tx.status === "pending") {
        const timeUsed = now - this.txModule.date;
        if (timeUsed > EXPIRY_TIME) {
          updateTX(this.txModule.txid, 'timeout')
        } else {
          this.setInputtedAddress(this.mainnetAddress)
          this.contractRunning = true
          this.pendingContract = true
          this.startTrack(tx.txid)
        }
      } else {
        console.log('this.txModule', this.txModule);
        this.showTXAlert = false
        this.contractRunning = false
      }
    })
  }
  startTrack(txid) {
    if (this.txModule === null) return
    keepTrackingRaw(txid).then((result) => {
      this.txModule.updateFromRawData(result)
      this.setContractRunning(false)
    })
  }
  async execute() {
    const result = await runExchange(this.mainnetAddress)
    if (result.exeSuccess) {
      const { data } = await saveTx(
        result.txId,
        this.testnetAddress,
        this.balance
      )
      this.txModule = new HistoryTXModuleStore(data)
      this.setContractRunning(true)
      this.setShowTXAlert(true)
      this.setHasHistory(true)
      this.startTrack(result.txId)
    }
    return result
  }
  async getHistoryTX() {
    const tx = await loadMyTx(this.testnetAddress)
    this.afterGotHistory(tx)
  }
}
