import { TX_STATUS } from "@alexgo-io/alex.js";
import { TARGET_TOKEN_WALLET_NAME } from "../stacks/stake-trade";

export class StakeForm {
  constructor(data) {
    //The token to stake
    this.token = TARGET_TOKEN_WALLET_NAME;
    this.tokenDisplayName = 'Alex';
    //The amount
    this.amount = data.amount;
    this.currentCycle = 0;
    this.cycleFrom = 1;
    this.cycleTo = 2;
    this.cycleCount = 12;
    this.blockFrom = 0;
    this.blockTo = 0;
    this.startTime = data.startTime || new Date();
    this.cyclesMax = [];
    this.cyclesEst = [];
    this.cyclesBar = [];

    this.txStatus = TX_STATUS.PENDING;
    this.txvo = {};
    this.refresher = 0
    this.exceeded = false;
  }
  static cloneVO(data) {
    const vo = new StakeForm(data);
    for (const key in data) {
      vo[key] = data[key];
    }
    return vo;
  }
  static defaultVO() {
    return new StakeForm({
      amount: 0,
    });
  }
}
