import { allExpiries } from './contracts-data';

const isMockNet = true;

const TESTNET_TX_API = 'https://stacks-node-api.testnet.stacks.co/extended/v1/';
const MOCKNET_TX_API = 'https://regtest-2.alexgo.io/extended/v1/';
const MOCKNETV3_TX_API = 'https://regtest-3.alexgo.io/extended/v1/';
const MOCKNETV4_TX_API = 'https://regtest-4.alexgo.io/extended/v1/';

export const LAPLACE_API = 'https://laplace.alexgo.io/';

export const API_HOST = isMockNet ? MOCKNET_TX_API : TESTNET_TX_API;
export async function jfecth(url, method = 'GET') {
  try {
    const response = await fetch(url, { method });
    const result = await response.json();
    return result;  
  } catch (error) {
    console.log(error);
  }
}
export const CURRENT_CONTRACT_DETAILS = {
  'ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.token-usda': {
    category: 'standardTokens',
    scaling: 1e8,
  },
  'ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.token-wbtc': {
    category: 'standardTokens',
    scaling: 1e8,
  },
  'ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.token-wstx': {
    category: 'standardTokens',
    scaling: 1e8,
  },
  'ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.token-alex': {
    category: 'standardTokens',
    scaling: 1e8,
  },
  'ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.token-t-alex': {
    category: 'standardTokens',
    scaling: 1e8,
  },
  'ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.fwp-wstx-usda-50-50': {
    'category': 'LPTokens',
    'scaling': 1e8,
    'pair': 'WSTX/USDA',
    'fromType': 'WSTX',
    'toType': 'USDA',
  },
  'ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.fwp-wstx-wbtc-50-50': {
    'category': 'LPTokens',
    'scaling': 1e8,
    'pair': 'WSTX/WBTC',
    'fromType': 'WSTX',
    'toType': 'WBTC',
  },
  'ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.fwp-wbtc-usda-50-50': {
    'category': 'LPTokens',
    'scaling': 1e8,
    'pair': 'WBTC/USDA',
    'fromType': 'WBTC',
    'toType': 'USDA',
  },
};

allExpiries.forEach(e => {
  CURRENT_CONTRACT_DETAILS[`ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.key-usda-${e}-wbtc`] = {
    'category': 'borrowTokens',
    'scaling': 1e8,
  }
  CURRENT_CONTRACT_DETAILS[`ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.key-wbtc-${e}-usda`] = {
    'category': 'borrowTokens',
    'scaling': 1e8,
  }
  CURRENT_CONTRACT_DETAILS[`ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.yield-wbtc-${e}`] = {
    'category': 'depositTokens',
    'scaling': 1e8,
  }
  CURRENT_CONTRACT_DETAILS[`ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.yield-usda-${e}`] = {
    'category': 'depositTokens',
    'scaling': 1e8,
  }
  CURRENT_CONTRACT_DETAILS[`ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.ytp-yield-wbtc-${e}-wbtc`] = {
    'category': 'LPTokens',
    'scaling': 1e8,
  };
  CURRENT_CONTRACT_DETAILS[
    `ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.ytp-yield-usda-${e}-usda`
  ] = {
    category: 'LPTokens',
    scaling: 1e8,
  };
  CURRENT_CONTRACT_DETAILS[`ST1J2JTYXGRMZYNKE40GM87ZCACSPSSEEQVSNB7DC.ytp-yield-usda-${e}-usda`] = {
    'category': 'LPTokens',
    'scaling': 1e8,
};
})

export const TOKEN_LIST = [
  {
    type: 'WBTC',
    confirmModalInfo: {
      tokenName: 'BTC',
    },
  },
  {
    type: 'USDA',
    confirmModalInfo: {
      tokenName: 'USDA'
    },
  },
]
