import { CONTRACTS } from '@alexgo-io/alex.js';
const storage = () => global.alexjs.getStorageManager();
export const runExchange = async (mainnetAddress) => {
  console.log('runExchange', CONTRACTS.EXCHANGE);
  const ec = global.alexjs.getContract(CONTRACTS.EXCHANGE);
  console.log(CONTRACTS.EXCHANGE);
  const result = await ec.exchange(mainnetAddress);
  return result;
};
export const saveTx = async (txid, account, amount) => {
  await deleteTXByAccount(account)
  const data = {
    txid,
    account,
    date: Date.now(),
    status: 'pending',
    amount
  };
  const list = await loadTx();
  list.push(data);
  const success = await storage().set(
    'exchange-tx',
    JSON.stringify({
      list,
    })
  );
  return { success, data };
};
export const loadTx = async () => {
  const data = await storage().get('exchange-tx');
  if (data === null) {
    return [];
  }
  const obj = JSON.parse(data);
  return obj.list;
};
export const loadMyTx = async (account) => {
  const list = await loadTx();
  return list.find((e) => {
    return e.account === account;
  });
};
export const deleteTX = async (txid) => {
  let list = await loadTx();
  list = list.filter((e) => {
    return e.txid !== txid;
  });
  const success = await storage().set(
    'exchange-tx',
    JSON.stringify({
      list,
    })
  );
  return success;
};

export const deleteTXByAccount = async (account) => {
  let list = await loadTx();
  list = list.filter((e) => {
    return e.account !== account;
  });
  const success = await storage().set(
    'exchange-tx',
    JSON.stringify({
      list,
    })
  );
  return success;
};

export const updateTX = async (txid, status) => {
  let list = await loadTx();
  list.forEach((e, i) => {
    if (e.txid === txid) {
      e.status = status
    }
  });
  const success = await storage().set(
    'exchange-tx',
    JSON.stringify({
      list,
    })
  );
  return success;
};

export const loadCurrentPage = async () => {
  const data = await storage().get('exchange-current-page');
  if (data === null) {
    return 'pre';
  }
  return data
};

export const saveCurrentPage = async (page) => {
  const success = await storage().set(
    'exchange-current-page',
    page
  );
  return success
};