/**
 * We can declare as much as functions to support auth thing around stack
 */
import { AppConfig, UserSession } from '@stacks/connect-react';
const appConfig = new AppConfig(
  ['store_write','store_read', 'publish_data'],
  'https://app.alexgo.io'
);
// For other parts need to invoke usersession funtions
export const userSession = new UserSession({ appConfig });
/**
 * Package an config for stack Connect login button
 * @param {*} onComplete for complete call back 
 * @returns 
 */
export function getAuthOptions({ onComplete }) {
  const onFinish = (data) => {
    console.log('onFinish:',data);
    const { userSession, authResponse } = data;
    
    const userData = userSession.loadUserData();
    onComplete(userData, authResponse);
  };

  const onCancel = () => {
    onComplete(null, null);
  };

  const authOptions = {
    manifestPath: '',
    redirectTo: '/deposit-borrow',
    userSession,
    onFinish,
    onCancel,
    appDetails: {
      name: 'ALEX App',
      icon: 'https://alexgo.io/wp-content/themes/alex/img/logo_tm.png',
    },
  };

  return {
    authOptions,
  };
}
/**
 * Sign out on stack user session and call back
 * @param {*} onComplete for complete call back 
 */
export function signOut({ onComplete }) {
  userSession.signUserOut();
  
  onComplete();
}
