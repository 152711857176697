export function getShortenDate(date, hasYear = false) {
  if(!date) return '';
  const now = new Date();
  const ds = date.toDateString().split(' ');
  const todayDS = now.toDateString().split(' ');
  const [w, m, d, y] = ds;
  const [tw, tm, td, ty] = todayDS;
  if (m === tm && ty === y && d - td === 1) {
    return 'Overnight';
  }
  if (hasYear) return `${m} ${d} ${y}`;
  return y === ty ? `${m} ${d}` : `${m} ${d} ${y}`;
}
export const blocksToDate = (blocks)=> {
  const timeTo = (blocks/240) * 3600 * 1000;
  // console.log('blocksToDate', blocks, timeTo);
  return new Date(Date.now() + timeTo)
}
export function getMiniDate(date, hasYear = false) {
  if(!date) return '';
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return `${bitTo2(m)}${bitTo2(d)}`;
}
export function bitTo2(n){
  if(n < 10) return `0${n}`
  return String(n);
}
/*
 * 1 block last 15 sec on mocknet, it could be 10 minutes on mainnet
 * given a stake end at block 60000
 * current block is 4000
 * then blocks of this function param should be 60000 - 4000 = 56000
 */
const SECONDS_IN_BLOCK = 15;
export const blocksToTime = (blocks)=> {
  const seconds = (blocks * SECONDS_IN_BLOCK);
  const d = (seconds / 86400).toFixed(0)
  const h = ((seconds % 86400) / 3600).toFixed(0);
  const m = ((seconds % 3600) / 60).toFixed(0);
  if(d === '0' && h === '0') return `${m}m`
  if(d === '0') return `${h}h ${m}m`
  return `${d}d ${h}h ${m}m`
}




