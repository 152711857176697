import { OrderVO, ORDER_TYPES } from '../template/OrderVO';

let storage;
const initStorage = () => {
  if (!global.alexjs) return;
  storage = global.alexjs.getStorageManager();
};
export const saveOrder = async (order) => {
  initStorage();
  const result = await storage.get('myorders');
  if (result && Array.isArray(result.orders)) {
    result.orders.push(order);
    await storage.set('myorders', result);
  } else {
    await storage.set('myorders', { orders: [order] });
  }
};

export const deleteOrder = async (orderId) => {
  initStorage();
  const result = await storage.get('myorders');
  if (result && Array.isArray(result.orders)) {
    const _orders = result.orders.map((o) => new OrderVO(o));
    const newArr = _orders.filter((o) => {
      return o.id !== orderId;
    });
    await storage.set('myorders', { orders: newArr });
  }
};

export const updateOrder = async (orderId, newOrder) => {
  initStorage();
  const cloned = JSON.parse(JSON.stringify(newOrder));
  await deleteOrder(orderId);
  await saveOrder(cloned);
};

export const updateTXStatus = async (newTXVO) => {
  initStorage();
  const result = await storage.get('myorders');
  if (result) {
    const _orders = result.orders.map((o) => new OrderVO(o));
    const target = _orders.find((o) => {
      return o.txId === newTXVO.txId;
    });
    if (target) {
      target.txStatus = newTXVO.status;
      target.txvo = newTXVO;
      await deleteOrder(target.id);
      await saveOrder(target);
      return target
    }
    return null;
  }
  return null;
};

export const getOrderByID = async (orderId) => {
  initStorage();
  const result = await storage.get('myorders');
  if (result) {
    const _orders = result.orders.map((o) => new OrderVO(o));
    const target = _orders.find((o) => {
      return o.id === orderId;
    });
    return target;
  }
  return null;
};
export const getOrders = async () => {
  initStorage();
  const result = await storage.get('myorders');
  if (result) {
    const _orders = result.orders.map((o) => new OrderVO(o)).sort((a,b)=>{
      return a.createdDate > b.createdDate ? -1 : 1;
    });
    return _orders;
  }
  return [];
};

export const testOrderData = async () => {
  initStorage();
  const vo = new OrderVO({
    tokenType: 'WBTC',
    type: ORDER_TYPES.DEPOSIT,
  });
  await saveOrder(vo);
  let orders = await getOrders();
  let order = await getOrderByID(vo.id);
  console.log('after save orders', orders);
  console.log('after save order', order);
  vo.txId = '0xxxxxx';
  await updateOrder(vo.id, vo);
  orders = await getOrders();
  order = await getOrderByID(vo.id);
  console.log('after update orders', orders);
  console.log('after update order', order);
  await deleteOrder(vo.id);
  orders = await getOrders();
  order = await getOrderByID(vo.id);
  console.log('after delete orders', orders);
  console.log('after delete order', order);
};
