import useSWR, { mutate } from 'swr';
import { parseTokens } from '../../tools/parseBalances';
import { API_HOST, jfecth, CURRENT_CONTRACT_DETAILS } from '../../stacks/base';
import { CONTRACTS } from '@alexgo-io/alex.js';

export const useBalances = (stxAddress, capitalization = true) => {
  const { data } = useSWR(`${API_HOST}address/${stxAddress}/balances`, jfecth);
  const sft = useSWR(stxAddress, getSFTBalances);
  let balances = {
    allTokens: {},
    standardTokens: {},
  };
  if (data && sft.data) {
    const mergedData = {
      ...data,
      fungible_tokens: {
        ...data.fungible_tokens,
        ...sft.data.fungible_tokens,
      },
    };
    if (mergedData['stx']) {
      balances['allTokens']['STX'] = Number(mergedData['stx']['balance']) / 1e6;
      balances['standardTokens']['STX'] =
        Number(mergedData['stx']['balance']) / 1e6;
    }
    for (const key in mergedData['fungible_tokens']) {
      let balance = mergedData['fungible_tokens'][key]['balance'];
      const [contract, token] = key.split('::');
      //We display tokens where the user's balance > 0 and is expected.
      if (balance > 0 && CURRENT_CONTRACT_DETAILS[contract]) {
        const { category, scaling } = CURRENT_CONTRACT_DETAILS[contract];
        const { parsedBalance, parsedToken } = parseTokens(
          token,
          scaling,
          balance,
          capitalization
        );
        if (balances[category] === undefined) balances[category] = {};
        balances[category][parsedToken] = parsedBalance;
        balances['allTokens'][parsedToken] = parsedBalance;
      }
    }
  }
  return balances;
};

export const mutateBalances = (stxAddress) => {
  mutate(`${API_HOST}address/${stxAddress}/balances`, jfecth);
};

const yWBTC = () => global.alexjs.getContract(CONTRACTS.YieldWBTC);
const yUSDA = () => global.alexjs.getContract(CONTRACTS.YieldUSDA);
const kUSDAWBTC = () => global.alexjs.getContract(CONTRACTS.Key_USDA_WBTC);
const kWBTCUSDA = () => global.alexjs.getContract(CONTRACTS.Key_WBTC_USDA);
const ytpWBTC = () => global.alexjs.getContract(CONTRACTS.YTPYieldWBTC);
const ytpUSDA = () => global.alexjs.getContract(CONTRACTS.YTPYieldUSDA);
export const getSFTBalances = async (...args) => {
  // console.log('FT:',yWBTC().getV1TokenName());
  return new Promise(async (resolve) => {
    const stxAddress = args[0];
    const promises = [];
    promises.push(getSFTBalance(yWBTC(), stxAddress));
    promises.push(getSFTBalance(yUSDA(), stxAddress));
    promises.push(getSFTBalance(kUSDAWBTC(), stxAddress));
    promises.push(getSFTBalance(kWBTCUSDA(), stxAddress));
    promises.push(getSFTBalance(ytpWBTC(), stxAddress));
    promises.push(getSFTBalance(ytpUSDA(), stxAddress));
    const results = await Promise.all(promises);
    let resultObj = {};
    for (let index = 0; index < results.length; index++) {
      const balanceObj = results[index];
      resultObj = { ...resultObj, ...balanceObj };
    }
    resolve({
      fungible_tokens: resultObj,
    });
  });
};

export const getSFTBalance = async (token, stxAddress) => {
  let rvo;
  const obj = {};
  rvo = await token.getTokenOwend(stxAddress);
  // console.log('getSFTBalance start', token.name, rvo.list.length);
  const promises = [];
  for (let index = 0; index < rvo.list.length; index++) {
    const tokenId = rvo.list[index].toNumber() / 1e8;
    promises.push(getBalanceOfTokenID(token,tokenId))
  }
  const balanceResult = await Promise.all(promises);
  balanceResult.forEach(element => {
    obj[token.getV1FullBalanceName(element.tokenId)] = {
      balance: element.value,
    };
  });
  return obj;
};
const getBalanceOfTokenID = async (token,tokenId)=>{
  const rvo = await token.getBalance(tokenId * 1e8);
  return { tokenId, value:rvo.getBN().toNumber() }
}
