import BN from 'bn.js';
export const decimalComma = (v) => {
  return Number(v).toLocaleString('en-US', { minimumFractionDigits: 0 });
};

export const idealNumber = (v) => {
  const n = Number(v);
  //Not a number return empty string
  if (isNaN(n)) return '';
  const ns = String(n);
  const arr = ns.split('.');
  //If the number is a decimal number
  if (arr.length === 1) {
    //Not a decimal number return the string
    return v;
  } else {
    if (arr[1].length > 6) {
      //The decimal place are longer than 6 like 1.33029303, fix it to 6
      return Number(v).toFixed(6);
    } else {
      //The number here is like 10.235, 8, 10002.334256
      return ns;
    }
  }
};

export const fixedNumber = (v, to = 6) => {
  const n = Number(v);
  return Number(n.toFixed(to));
};

export const nFormatter = (num, digits = 2, from = 1) => {
  // console.log('nFormatter in:',num, 'from', from);
  if (num < 1e3) {
    return String(num.toFixed(digits));
  }
  const lookup = [
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'm' },
    { value: 1e9, symbol: 'b' },
    { value: 1e12, symbol: 't' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  const result =
    (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol;
  // console.log('nFormatter out', result);
  return result;
};

export const sbnDiv1e8 = (n, d) => {
  if (n.bitLength() >= 53) {
    return n.div(new BN(1e8)).toString();
  }
  return Number(n.toNumber() / 1e8).toFixed(d);
};

export const safeBNDiv1e8 = (n) => {
  if (n.bitLength() >= 53) {
    return Number(n.div(new BN(1e8)).toString());
  }
  return Number(n.toNumber() / 1e8);
};

export const tillNo0Decimal = (n) => {
  if (n > 1) return n.toFixed(2);
  const str = String(n);
  for (let index = 2; index < str.length - 2; index++) {
    const element = str.charAt(index);
    if (element !== '0') {
      return n.toFixed(index);
    }
  }
  return n;
};
const findNon0End = (n) => {
  const arr = String(n).split('.');
  if (arr.length === 1) return 0;
  const str = arr[1];
  for (let index = arr[1].length - 1; index >= 0; index--) {
    if (str.charAt(index) !== '0') {
      return index + 1;
    }
  }
  return 0;
};
export const tillNo0XBit = (n, b) => {
  const ft = Math.min(findNon0End(n), b);
  return Number(n).toFixed(ft);
};
