import { createContext, useReducer } from 'react';
import {
  CYCLE_BLOCKS,
  getCurrentBlockHeight,
  getEarningChartData,
  getEarningChartDataV2,
  getRewardCycle,
  getRewards,
  getStakedBlocks,
  getStakedList,
  getStartBlock,
  STAKE_APR_FACTOR,
} from '../../stacks/stake-trade';
import { StakeForm } from '../../template/StakeForm';
import { blocksToDate, getShortenDate } from '../../tools/date';
import stakeReducer from './stake.reducer';
import actionTypes from './stake.types';
import { average, sum } from '../../tools/array';
import { tillNo0XBit } from '../../tools/numbers';
export const StakeContext = createContext(StakeForm.defaultVO());
const CYCLE_UP_TO = 32;
const cacheCycleMax = [];
const StakeProvider = ({ children }) => {
  const [store, dispatch] = useReducer(stakeReducer, StakeForm.defaultVO());
  const updateFormData = (newValue) => {
    dispatch({
      type: actionTypes.UPDATE,
      payload: newValue,
    });
  };
  const resetCycleChartData = async (inCycle = 12) => {
    const cb = await getCurrentBlockHeight();
    const currentCycle = await getRewardCycle(cb);
    const cycleFrom = currentCycle + 1;
    const cycleTo = cycleFrom + inCycle - 1;
    const blockFrom = await getStartBlock(cycleFrom);
    const blockTo = blockFrom + CYCLE_BLOCKS * inCycle;
    const startTime = blocksToDate(blockFrom - cb);

    updateFormData({
      blockFrom,
      blockTo,
      startTime,
      cycleFrom,
      cycleTo,
      cycleCount: inCycle,
      currentCycle,
    });

    let cyclesMax = [];
    const cyclesEst = [];
    const cyclesBar = [];
    if (cacheCycleMax.length === 0) {
      const chartData = await getEarningChartDataV2();
      for (let index = 0; index < chartData.length; index++) {
        const vTo = chartData[index]; //last + stepValue * (0.5 + Math.random() * 2);
        const element = { x: index, y: Number(vTo.toFixed(2)) };
        cyclesMax.push(element);
        cacheCycleMax.push(element);
      }
    } else {
      cyclesMax = cacheCycleMax;
    }
    let skip = 0;
    let innerI = 0;
    for (let index = 0; index < cyclesMax.length; index++) {
      const estTo =
        inCycle - 1 < cyclesMax.length
          ? cyclesMax[inCycle - 1]
          : cyclesMax[cyclesMax.length - 1]; //cyclesMax[skip + index];
      const vTo = cyclesMax[skip + index];
      cyclesEst.push({ x: index, y: estTo.y });
      innerI++;
      if (innerI === inCycle + 1) {
        innerI = 0;
        skip--;
        cyclesBar.push({ x: index, y: vTo.y });
      }
    }
    updateFormData({
      cyclesMax,
      cyclesEst,
      cyclesBar,
    });
  };
  const resetCycleChartDataDisconnect = async (inCycle = 12) => {
    const currentCycle = 58;
    const chartData = await getEarningChartData(currentCycle);
    console.log('currentCycle', currentCycle);
    const cycleFrom = currentCycle + 1;
    const cycleTo = cycleFrom + inCycle - 1;
    const blockFrom = await getStartBlock(cycleFrom);
    const blockTo = blockFrom + CYCLE_BLOCKS * inCycle;
    const startTime = new Date();
    let cyclesMax = [];
    const cyclesEst = [];
    const cyclesBar = [];

    const base = 300;
    const stepValue = 30;
    let last = base;
    if (cacheCycleMax.length === 0) {
      for (let index = 0; index < chartData.length; index++) {
        const vTo = chartData[index]; //last + stepValue * (0.5 + Math.random() * 2);
        const element = { x: index, y: Number(vTo.toFixed(3)) };
        cyclesMax.push(element);
        cacheCycleMax.push(element);
        last = vTo;
      }
    } else {
      cyclesMax = cacheCycleMax;
    }
    let skip = 0;
    let innerI = 0;
    for (let index = 0; index < chartData.length; index++) {
      const vTo = cyclesMax[0]; //cyclesMax[skip + index];
      cyclesEst.push({ x: index, y: vTo.y });
      innerI++;
      if (innerI === inCycle + 1) {
        innerI = 0;
        skip--;
        cyclesBar.push({ x: index, y: vTo.y });
      }
    }
    console.log('cyclesEst', cyclesEst);
    updateFormData({
      blockFrom,
      blockTo,
      startTime,
      cycleFrom,
      cycleTo,
      cycleCount: inCycle,
      cyclesMax,
      cyclesEst,
      cyclesBar,
      currentCycle,
    });
  };
  const getMyStakingTableList = async () => {
    const cb = await getCurrentBlockHeight();
    const currentCycle = await getRewardCycle(cb);
    const nextCycle = 1 + currentCycle;
    const maxCycle = nextCycle + CYCLE_UP_TO;
    let stakeds = await getStakedList(0, maxCycle);

    let startPoint = 0;
    let gotStartPoint = false;
    for (let index = 0; index < stakeds.length; index++) {
      if (stakeds[index]['amount-staked'] !== 0 && !gotStartPoint) {
        startPoint = index;
        gotStartPoint = true;
      }
    }
    let rewards = await getRewards(0, maxCycle);
    const amountStakeds = stakeds.map((e) => e['amount-staked']);
    const myStaked = Math.max(...amountStakeds);
    // console.log('getMyStakingTableList:',stakeds, rewards);
    const apys = rewards.map((reward, index) => {
      if (amountStakeds[index] > 0) {
        return reward / amountStakeds[index];
      }
      return 0;
    });

    const apysToPercent = (apys) => {
      const no0apys = apys.filter((a) => {
        return a !== 0;
      });
      const a = 1 + average(no0apys);
      const b = STAKE_APR_FACTOR;
      const apy = (Math.pow(a, b) - 1) * 100;
      if (isFinite(apy)) return apy.toFixed(2);
      return Number.MAX_VALUE;
    };
    const apysToAPR = (apys) => {
      const no0apys = apys.filter((a) => {
        return a !== 0;
      });
      return (average(no0apys) * STAKE_APR_FACTOR * 100).toFixed(2);
    };
    const apy = apysToAPR(apys); //apysToPercent(apys.slice(startPoint));
    const endPRCycle = nextCycle - 1 >= 1 ? nextCycle - 1 : 1;
    const pendingReward = tillNo0XBit(sum(rewards.slice(0, endPRCycle)), 8);
    const stakedBlocks = await getStakedBlocks(0, stakeds.length);
    console.log('getMyStakingTableList:', stakeds, rewards);
    const getStakeStatus = (index, nextCycle) => {
      if (index === nextCycle - 1) return 'Current';
      if (index >= nextCycle) return 'Upcoming';
      return 'Finished';
    };
    const tempList = [];
    for (let index = 0; index < stakeds.length; index++) {
      const staked = stakeds[index];
      if (staked['amount-staked'] > 0) {
        const blocksFrom = stakedBlocks[index];
        const blocksTo = stakedBlocks[index] + CYCLE_BLOCKS;
        const claimReward = rewards[index];
        const claimStake = staked['to-return'];
        tempList.push({
          id: index.toString(),
          myStake: staked['amount-staked'],
          type: 'ALEX',
          stakeStatus: getStakeStatus(index, nextCycle), // Current,Finished,Upcoming
          apy: apys[index],
          claim: `${claimStake}`,
          cycle: index,
          blocksFrom,
          blocksTo,
          claimReward,
          claimStake,
          currentBlock: cb,
          endTime: getShortenDate(blocksToDate(blocksTo - cb)),
          harvestStatus: claimReward !== 0 ? 'harvest' : 'none', // harvest,none,pending
        });
      }
    }

    const tableList = [];
    if (tempList.length === 0) return [];
    if (tempList.length === 1) {
      const tableList = [
        {
          ...tempList[0],
          apy,
          cycleFrom: tempList[0].cycle,
          cycleTo: tempList[0].cycle,
          canHarvest: tempList[0].cycle < nextCycle - 1,
          canUnStake: tempList[0].cycle < nextCycle - 1,
          harvestToCycle: tempList[0].cycle,
          pendingRewards: Number(pendingReward),
        },
      ];
      const oneData = [
        {
          id: 1,
          type: 'ALEX',
          myStake: myStaked,
          apy: apy,
          pendingReward: Number(pendingReward),
          detailTableData: tableList,
        },
      ];
      return oneData;
    }
    let nextElement = tempList[1];
    let from = tempList[0].cycle;
    let fromIndex = 0;
    const rollUpStatus = (arr) => {
      if (arr.indexOf('Current') !== -1) return 'Current';
      return arr[0];
    };
    const rollUp = (from, to, arr) => {
      const subArray = arr.slice(from, to);
      console.log('rollUp', arr, subArray, from, to);
      const apys = subArray.map((s) => s.apy);
      let harvestToCycle = 0;
      const pendingRewards = subArray
        .filter((s) => {
          if (s.cycle < currentCycle) {
            harvestToCycle = s.cycle;
          }
          return s.cycle < currentCycle;
        })
        .map((s) => s.claimReward);
      const stakeStatusArr = subArray.map((s) => s.stakeStatus);
      const claimArr = subArray.map((s) => s.claimStake);
      return {
        apy: apysToAPR(apys), //apysToPercent(apys),
        pendingRewards: sum(pendingRewards),
        stakeStatus: rollUpStatus(stakeStatusArr),
        harvestToCycle,
        claim:
          subArray[subArray.length - 1].cycle < currentCycle
            ? tillNo0XBit(sum(claimArr), 8)
            : 0,
      };
    };
    for (let index = 0; index < tempList.length; index++) {
      const element = tempList[index];
      nextElement = tempList[index + 1];
      if (nextElement) {
        if (element.myStake !== nextElement.myStake) {
          const merged = rollUp(fromIndex, index + 1, tempList);
          tableList.push({
            ...element,
            cycleFrom: from,
            cycleTo: element.cycle,
            blocksFrom: tempList[fromIndex].blocksFrom,
            canHarvest: from < nextCycle - 1,
            canUnStake: element.cycle < nextCycle - 1,
            ...merged,
          });
          from = nextElement.cycle;
          fromIndex = index + 1;
        }
      } else {
        const merged = rollUp(fromIndex, index + 1, tempList);
        tableList.push({
          ...element,
          cycleFrom: from,
          cycleTo: element.cycle,
          canHarvest: from < nextCycle - 1,
          canUnStake: element.cycle < nextCycle - 1,
          blocksFrom: tempList[fromIndex].blocksFrom,
          ...merged,
        });
      }
    }
    const unfinished = tableList.filter((t) => {
      return t.stakeStatus !== 'Finished';
    });
    const finished = tableList.filter((t) => {
      return t.stakeStatus === 'Finished';
    });
    return [
      {
        id: 1,
        type: 'ALEX',
        myStake: myStaked,
        apy: apy,
        pendingReward: pendingReward,
        detailTableData: [...unfinished, ...finished],
      },
    ];
  };
  return (
    <StakeContext.Provider
      value={{
        store,
        updateFormData,
        resetCycleChartData,
        resetCycleChartDataDisconnect,
        getMyStakingTableList,
      }}
    >
      {children}
    </StakeContext.Provider>
  );
};

export default StakeProvider;
