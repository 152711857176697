import { HeartOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRef } from 'react';
import { WarningIcon } from '../iconset/WarningIcon';
import styles from './common.module.css';

const UnSupportAlert = ({ type = 'broswer' }) => {
  const browserNotSupport =
    'Use Google Chrome to access app.alexgo.io for Web experience.Alternatively, download Google Chrome on your desktop computer.';
  const walletNotInstall =
    'ALEX need hiro wallet to run properly. Please install the wallet extension and refresh.';
  const maintenanceNotice =
    'Our site is under maintenance and will be back within the next 24 hours.';
  const thanksForYourLove = (
    <div>
      Thank you for testing with us and see you at{' '}
      <a href='https://app.alexlab.co'>https://app.alexlab.co</a>
  </div>
  );
  const getText = () => {
    if (type === 'broswer') {
      return browserNotSupport;
    }
    if (type === 'wallet') {
      return walletNotInstall;
    }
    return thanksForYourLove;
  };
  const getTitle = () => {
    if (type === 'broswer') {
      return 'Browser not supported';
    }
    if (type === 'wallet') {
      return 'Hiro Wallet not installed';
    }
    return 'Thank you';
  };
  const getButtonText = () => {
    if (type === 'broswer') {
      return 'Get Google Chrome for Deskto';
    }
    if (type === 'wallet') {
      return 'Get Hiro Wallet for Chrome';
    }
    return 'Thank you';
  };
  const bgRef = useRef();
  return (
    <div ref={bgRef} className={styles.txAlertBG}>
      <div className={styles.txAlertBox}>
        <div className='flex items-center h-16 mt-6'>
        <HeartOutlined style={{ fontSize: '16px', color: 'red' }}/>
          <p className='text-center text-xl font-bold ml-2'>{getTitle()}</p>
        </div>
        <p className='p-6 text-gray-600 text-center '>{getText()}</p>
        {type !== 'maintenance' && (
          <Button
            type='primary'
            size='large'
            style={{
              borderRadius: '24px',
              paddingLeft: '4rem',
              paddingRight: '4rem',
              marginTop: '2rem',
            }}
            onClick={() => {
              if (type === 'broswer') {
                window.open('https://www.google.com/chrome/', '_self');
              } else {
                window.open(
                  'https://chrome.google.com/webstore/detail/hiro-wallet/ldinpeekobnhjjdofggfgjlcehhmanlj',
                  '_self'
                );
              }
            }}
          >
            {getButtonText()}
          </Button>
        )}
      </div>
    </div>
  );
};
export default UnSupportAlert;
