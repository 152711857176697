export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const isTest = false
const MONEY_API_HOST = isTest ? 'http://localhost:4000/' : 'https://alextest.xyz/';
export const showMeMoneyApi = `${MONEY_API_HOST}showMeTheMoney5`;
export const checkStatusApi = `${MONEY_API_HOST}checkFaucet`;

const faucetHost = isTest
  ? 'http://127.0.0.1:8080'
  : 'https://faucet.alexgo.io'
export const consumeApi = `${faucetHost}/code/consume`
export const saveLog = `${faucetHost}/log`
export const getLog = `${faucetHost}/log/getAll`

export const STATUS = {
  sent: 1
}

export function msgSubmitted(txid) {
  return {
    text: 'Transaction submitted',
    type: 'pending',
    link: `https://explorer.alexgo.io/txid/${txid}?chain=mainnet`
  }
}

export function msgSuccess() {
  return {
    text: 'Tokens sent, please check in your wallet',
    type: 'success'
  }
}

export function msgPending() {
  return {
    show: true,
    text: 'Waiting in queue',
    type: 'pending',
  }
}

export function msgError(text) {
  return {
    text,
    type: 'error',
  }
}

export function hideString(str, start = 10, end = 8, remain = 5) {
  if (!str) return ''
  const arr = str.split('')
  return arr.map((char, index) => {
    return (index > start - 1 && index < str.length - end) ? '*' : char
  }).join('').replace(/\*{2,}/, '*'.repeat(remain))
}
