import { CONTRACTS } from '@alexgo-io/alex.js';
import {
  createMarginPattern,
  getContractTokenName,
  getTokenContractName,
  getYokenContractName,
} from '../template/token-pattens';
import { blocksToDate } from '../tools/date';
import { decimalComma } from '../tools/numbers';
import { getBalances } from './account';
import { jfecth, LAPLACE_API } from './base';
import { cacheData, getCached } from './cache-manager';

export const ytp = () => global.alexjs.getContract(CONTRACTS.YieldTokenPool);
export const crp = () =>
  global.alexjs.getContract(CONTRACTS.CollateralRebalancingPool);
export const fwp = () => global.alexjs.getContract(CONTRACTS.FWP);
const oracle = () => global.alexjs.getContract(CONTRACTS.OpenOracle);

export const OLD_EXPIRIES = [];
export const EXPIRIES = [34561]; //[132481, 172802];

//key-<token>-<maturity>-<collateral>
export const allExpiries = [...OLD_EXPIRIES, ...EXPIRIES];
export const VALID_LPTOKENS = ['fwp-stx-wbtc-50-50', 'fwp-stx-usda-50-50'];
const initValidLPTokens = () => {
  const exps = allExpiries.reverse();
  exps.forEach((e) => {
    let yiledToken = getYokenContractName('WBTC', e);
    let ytpToken = `ytp-${yiledToken}-wbtc`.toLowerCase();
    VALID_LPTOKENS.push(ytpToken);
    yiledToken = getYokenContractName('USDA', e);
    ytpToken = `ytp-${yiledToken}-usda`.toLowerCase();
    VALID_LPTOKENS.push(ytpToken);
  });
};
initValidLPTokens();
export const BORROWTOKENS = [];
//yield-<token>-<maturity>
export const DEPOSITTOKENS = [];
allExpiries.forEach((e) => {
  BORROWTOKENS.push(`key-wbtc-${e}-usda`);
  BORROWTOKENS.push(`key-usda-${e}-usda`);
  BORROWTOKENS.push(`key-wbtc-${e}-wbtc`);
  BORROWTOKENS.push(`key-usda-${e}-wbtc`);
  DEPOSITTOKENS.push(`yield-usda-${e}`);
  DEPOSITTOKENS.push(`yield-wbtc-${e}`);
});
export const getBlockInfo = async () => {
  const result = await global.alexjs.getBlockInfo();
  return result;
};
export const getTokenDecimal = async (token, expiry) => {
  const cachedData = getCached(`getTokenDecimal-${token}`, { token });
  if (cachedData !== null) {
    return cachedData.data;
  }
  let tokenCName = getTokenContractName(token);
  let tokenContract;
  let resultDecimals;
  if (
    token.startsWith('yield') ||
    token.startsWith('ytp') ||
    token.startsWith('key')
  ) {
    //TODO: leftover
    if (expiry === undefined) {
      return 8;
    }
    if (token.startsWith('yield')) {
      tokenCName = toSFTYield(token).tokenName;
    } else if (token.startsWith('ytp')) {
      tokenCName = toSFTYTP(token).ytpTokenName;
    } else {
      tokenCName = toSFTKey(token).kTokenName;
    }

    tokenContract = global.alexjs.getContract(tokenCName);
    console.log('getTokenDecimal', tokenCName);
    resultDecimals = await tokenContract.getDecimalsSFT(expiry);
  } else {
    tokenContract = global.alexjs.getContract(tokenCName);
    resultDecimals = await tokenContract.getDecimals();
  }
  const dToken = resultDecimals.getBN().toNumber();
  if (dToken !== 0) {
    cacheData(`getTokenDecimal-${token}`, { token }, dToken);
  }
  return dToken;
};
export const getOraclePrice = async (_token) => {
  const token = _token.toUpperCase();
  const cachedData = getCached(`getOraclePrice-${token}`, { token });
  if (cachedData !== null) {
    return cachedData.data;
  }
  let result = await oracle().getPrice('coingecko', token);
  const value = result.getBN().toNumber();
  if (value !== 0) {
    cacheData(`getOraclePrice-${token}`, { token }, value);
  }
  return value;
};
export const getMaturityDateTemp = () => {
  return EXPIRIES.map((e) => {
    return { date: new Date(), apy: '0.00', expiry: e };
  });
};
export const getMaturityDates = async () => {
  const expiries = EXPIRIES;
  const blockInfo = await getBlockInfo();
  const mds = expiries.map((e) => {
    const blocksToComplete = e - blockInfo.blockHeight;
    const date = blocksToDate(blocksToComplete, blockInfo.blockTime);
    return { date, expired: blocksToComplete < 0 };
  });
  return mds;
};
export const getMaturityDateMap = async () => {
  const expiries = [...OLD_EXPIRIES, ...EXPIRIES];
  const blockInfo = await getBlockInfo();
  const dateMap = {};
  expiries.forEach((e) => {
    const blocksToComplete = e - blockInfo.blockHeight;
    dateMap[e] = blocksToDate(blocksToComplete, blockInfo.blockTime);
  });
  return dateMap;
};
export const getTokenPrices = async () => {
  let wbtc = await getOraclePrice('WBTC');
  let usda = await getOraclePrice('USDA');
  let stx = await getOraclePrice('WSTX');
  return { wbtc, usda, stx };
};
export const getFWPPools = async () => {
  const result = await fwp().getPools();
  return result;
};
export const getAmountTo = async (
  tokenX,
  tokenY,
  weightX,
  weightY,
  amount = 1
) => {
  const result = await fwp().getHelper(
    tokenX,
    tokenY,
    weightX,
    weightY,
    amount * 1e8
  );
  return result.getNumberValue();
};
export const getCRPPoolDetail = async (tokenX, tokenY, expiry) => {
  const cachedData = getCached(`getCRPPoolDetail`, { tokenX, tokenY, expiry });
  if (cachedData !== null) {
    return cachedData.data;
  }
  const result = await crp().getPoolDetails(tokenX, tokenY, expiry * 1e8);
  console.log('getCRPPoolDetail', result);
  cacheData(`getCRPPoolDetail`, { tokenX, tokenY, expiry }, result.tupleValue);
  return result.tupleValue;
};
export const getYTPPoolDetail = async (yToken) => {
  const cachedData = getCached(`getYTPPoolDetail`, { yToken });
  if (cachedData !== null) {
    return cachedData.data;
  }
  const result = await ytp().getPoolDetails(yToken);
  cacheData(`getYTPPoolDetail`, { yToken }, result.tupleValue);
  return result.tupleValue;
};
export const getFWPPoolDetail = async (tokenX, tokenY, weightX, weightY) => {
  const cachedData = getCached(`getFWPPoolDetail`, {
    tokenX,
    tokenY,
    weightX,
    weightY,
  });
  if (cachedData !== null) {
    return cachedData.data;
  }
  const result = await fwp().getPoolDetails(tokenX, tokenY, weightX, weightY);
  cacheData(
    `getFWPPoolDetail`,
    { tokenX, tokenY, weightX, weightY },
    result.tupleValue
  );
  return result.tupleValue;
};
export const getLTV = async (tokenA, tokenB, expiry) => {
  console.log('run getLTV', expiry);
  const cachedData = getCached('getLTV', { tokenA, tokenB, expiry });
  if (cachedData !== null) {
    return cachedData.data;
  }
  const yToken = getYokenContractName(tokenA.split('-')[1], expiry);
  const promiseList = [
    crp().getLTV(tokenA, tokenB, expiry * 1e8),
    ytp().getPrice(yToken),
  ];
  let [result, priceResult] = await Promise.all(promiseList);
  const ltv = result.getBN().toNumber();
  let price = priceResult.getBN().toNumber();
  if (price === 0) price = 1;

  console.log(
    'getLTV-->',
    yToken,
    tokenA,
    tokenB,
    expiry,
    ltv,
    price,
    ltv / price
  );
  return ltv / price;
};
export const getYield = async (yToken, from) => {
  const result = await ytp().getYield(yToken);
  const theYield = result.getBN().toNumber();
  console.log('getYield', yToken, 'from:', from);
  return theYield;
};
export const getAPY = async (
  expiry,
  yToken,
  theYield = undefined,
  bi = undefined
) => {
  let y = theYield;
  if (theYield === undefined) {
    y = await getYield(`${yToken}-${expiry}`, 1);
  }
  let blockInfo = bi;
  if (bi === undefined) {
    blockInfo = await getBlockInfo();
  }
  const apy = (
    ((y * 2102400) / (expiry * 1e8 - blockInfo.blockHeight * 1e8)) *
    100
  ).toFixed(2);
  return apy;
};
export const getAPYS = async (yToken) => {
  console.log('getAPYS', yToken);
  const blockInfo = await getBlockInfo();
  const promises = [];
  EXPIRIES.forEach((exp) => {
    promises.push(getAPY(exp, yToken, undefined, blockInfo));
  });
  const results = await Promise.all(promises);
  return results;
};
export const getAPYSInExpiries = async (yToken) => {
  const blockInfo = await getBlockInfo();
  const theYield1 = await getYield(`${yToken}-${EXPIRIES[0]}`, 2);
  const theYield2 = await getYield(`${yToken}-${EXPIRIES[1]}`, 2);
  const theYield3 = await getYield(`${yToken}-${EXPIRIES[2]}`, 2);
  const apy1 = await getAPY(EXPIRIES[0], yToken, theYield1, blockInfo);
  const apy2 = await getAPY(EXPIRIES[1], yToken, theYield2, blockInfo);
  const apy3 = await getAPY(EXPIRIES[2], yToken, theYield3, blockInfo);
  const result = {};
  result[EXPIRIES[0]] = apy1;
  result[EXPIRIES[1]] = apy2;
  result[EXPIRIES[2]] = apy3;
  return result;
};
export const getTotalDBBalances = async (borrowTokens, depositTokens) => {
  const wbtc = await getOraclePrice('WBTC');
  const usda = await getOraclePrice('USDA');
  let borrowBalance = 0;
  let depositBalance = 0;
  borrowTokens.forEach((element) => {
    const { key, value } = element;
    const arr = key.split('-');
    const price = arr[1].toLowerCase() === 'wbtc' ? wbtc : usda;
    const balance = (value * price) / usda;
    borrowBalance += balance;
  });
  depositTokens.forEach((element) => {
    const { key, value } = element;
    const arr = key.split('-');
    const price = arr[1].toLowerCase() === 'wbtc' ? wbtc : usda;
    const balance = (value * price) / usda;
    depositBalance += balance;
  });
  return { borrowBalance, depositBalance };
};

export const updateBalances = async (address) => {
  const tokenWBTC = global.alexjs.getContract('token-wbtc');
  const tokenUSDA = global.alexjs.getContract('token-usda');
  let result = await tokenWBTC.getDecimals();
  const wbtcScale = result.getBN().toNumber();
  result = await tokenUSDA.getDecimals();
  const usdaScale = result.getBN().toNumber();
  const scale = 1000000;
  const marginTokens = ['key-wbtc', 'key-usda'];

  result = await getBalances(address);
  
  const stx = String(Number(result.stx.balance) / scale);
  const ft = result['fungible_tokens'];
  const newBalances = {
    STX: stx,
  };
  const marginPositionBalances = {};
  const borrowTokens = [];
  const depositTokens = [];
  for (const key in ft) {
    const arr = key.split('::');
    if (arr[1] === 'usda') {
      newBalances['USDA'] = String(
        Number(ft[key]['balance']) / Math.pow(10, usdaScale)
      );
    } else if (arr[1] === 'wbtc') {
      newBalances['WBTC'] = String(
        Number(ft[key]['balance']) / Math.pow(10, wbtcScale)
      );
    } else {
      if (BORROWTOKENS.includes(arr[1])) {
        borrowTokens.push({ key: arr[1], value: Number(ft[key]['balance']) });
      }
      if (DEPOSITTOKENS.includes(arr[1])) {
        depositTokens.push({ key: arr[1], value: Number(ft[key]['balance']) });
      }
      let hasMToken = false;

      for (let index = 0; index < marginTokens.length; index++) {
        const mt = marginTokens[index];
        if (arr[1].startsWith(mt)) {
          hasMToken = true;
          break;
        }
      }
      if (hasMToken) {
        marginPositionBalances[arr[1]] = String(Number(ft[key]['balance']));
      }
    }
  }
  return {
    newBalances,
    marginPositionBalances,
    borrowTokens,
    depositTokens,
  };
};
export const getMarginMaxAmount = async (marginVO, balances) => {
  const pattern = createMarginPattern(marginVO);
  let ltv = await getLTV(pattern.token3, pattern.token4, pattern.expiry);
  let leverage = 1 / (1 - ltv);
  let ma = 0;
  if (marginVO.term === 'long') {
    ma = (Number(balances.WBTC) * leverage * 0.99).toFixed(8);
  } else {
    if (balances.USDA === 0) return 0;
    const wbtcprice = await getOraclePrice('WBTC');
    ma = (
      ((Number(balances.USDA) * leverage) / (wbtcprice / 1e8)) *
      0.99
    ).toFixed(8);
  }
  return ma;
};
export const checkLiquidity = async (marginVO) => {
  const yToken = getYokenContractName(
    getContractTokenName(marginVO.toToken),
    marginVO.expiry
  );
  const amount = marginVO.getRealAmount();
  const target = amount - marginVO.margin;
  const result = await ytp().getXGivenY(yToken, target);
  const al = result.getBN().toNumber();

  console.log('checkLiquidity', result.success, al);
  return result.success;
};
export const getALFromPoolDetail = async (type) => {
  // const wbtc = await getOraclePrice('WBTC');
  // const usda = await getOraclePrice('USDA')
  let lq = 0;
  let ltype = type.toLowerCase()
  try {
    const lpTokens = VALID_LPTOKENS.map((t) => {
      return t.replaceAll('stx', 'wstx');
    });
    const targetTokens = lpTokens.filter(t => {
      return t.includes(ltype)
    })
    const poolDetails = await jfecth(
      `${LAPLACE_API}basic/get_liquidity_details?pool_tokens=${targetTokens.join(
        ','
      )}`
    );
    console.log('getALFromPoolDetail', poolDetails, targetTokens);
    for (let index = 0; index < poolDetails.length; index++) {
      const element = poolDetails[index];
      lq += Number(element.values.liquidity);
    }
  } catch (error) {
    console.log('getALFromPoolDetail:', error);
    return 0;
  }
  return decimalComma(lq.toFixed(2));
};
export const getRateRange = async (token) => {
  const blockInfo = await getBlockInfo();
  let bRateFrom = 999;
  let dRateFrom = 0;
  let y;
  const exps = EXPIRIES.filter((e) => {
    const blocksToComplete = e - blockInfo.blockHeight;
    return blocksToComplete > 0;
  });
  const promises = [];
  for (let index = 0; index < exps.length; index++) {
    const exp = exps[index];
    promises.push(getYield(`yield-${token}-${exp}`, 4));
  }
  const results = await Promise.all(promises);

  results.forEach((r, index) => {
    const exp = exps[index];
    y = r;
    y = ((y * 2102400) / (exp * 1e8 - blockInfo.blockHeight * 1e8)) * 100;
    console.log('getRateRange result:', y);
    bRateFrom = Math.min(bRateFrom, y);
    dRateFrom = Math.max(dRateFrom, y);
  });
  return { bRateFrom, dRateFrom };
};
export const getAmountMax = async (orderVO, balances) => {
  if (orderVO.type === undefined) return 0;
  // if (orderVO.type === ORDER_TYPES.BORROW) {

  const collateral = getTokenContractName(
    getContractTokenName(orderVO.toToken)
  );
  const tokenBorrow = getTokenContractName(
    getContractTokenName(orderVO.tokenType)
  );
  const ltv = await getLTV(tokenBorrow, collateral, orderVO.expiry);
  const balance = balances[orderVO.toToken];
  let bAmount = balance * ltv;
  const priceC = await getOraclePrice(orderVO.toToken);
  const priceB = await getOraclePrice(orderVO.tokenType);
  const bDecimal = await getTokenDecimal(orderVO.tokenType, orderVO.expiry);
  const dDecimal = await getTokenDecimal(orderVO.tokenType, orderVO.expiry);
  bAmount = ((bAmount * priceC) / priceB) * 0.99;
  const dAmount = Number(balances[orderVO.tokenType]) * 0.99;
  console.log('getAmountMax', balance, bDecimal, dDecimal, priceC, priceB);
  return {
    bAmount: bAmount.toFixed(bDecimal),
    dAmount: dAmount.toFixed(dDecimal),
  };
};
export const getUPToLTV = async (orderVO) => {
  let result = 0;

  const collateral = getTokenContractName(
    getContractTokenName(orderVO.toToken)
  );
  const tokenBorrow = getTokenContractName(
    getContractTokenName(orderVO.tokenType)
  );
  for (let index = 0; index < EXPIRIES.length; index++) {
    const exp = EXPIRIES[index];
    const ltv = await getLTV(tokenBorrow, collateral, exp);
    console.log('getUPToLTV', tokenBorrow, collateral, ltv);
    result = Math.max(ltv, result);
  }
  return result;
};
export const getMaturityDate = async (blocks) => {
  const blockInfo = await global.alexjs.getBlockInfo();
  const blocksToComplete = blocks - blockInfo.blockHeight;
  const maturityDate = blocksToDate(blocksToComplete, blockInfo.blockTime);
  return { maturityDate, blocksToComplete };
};
const calculateUnexpiredPositionValue = (balance, ltv) => {
  let pnl = balance * (1 / ltv - 1);
  pnl = Math.max(pnl, 0);
  return pnl;
};
const calculateUnexpiredLeverageValue = (ltv) => {
  let lev = 1 / (1 - ltv);
  lev = Math.max(lev, 0);
  return lev;
};
export const getUnexpiredPositionDetails = async (
  token,
  collateral,
  exp,
  ltv,
  balance,
  priceToken,
  priceCollateral
) => {
  const detail = await getCRPPoolDetail(
    `token-${token}`,
    `token-${collateral}`,
    exp
  );
  let wx = detail['weight-x'].value.toNumber() / 1e8;
  let wy = detail['weight-y'].value.toNumber() / 1e8;
  let posToken = (balance / ltv) * wy;
  let posCollateral = ((balance * priceToken) / priceCollateral / ltv) * wx;
  // let pnl = (balance * (1 / ltv - 1) * priceToken) / priceCollateral;
  let pnl = calculateUnexpiredPositionValue(balance, ltv);
  let lev = calculateUnexpiredLeverageValue(ltv);
  return { posToken, posCollateral, pnl, lev };
};
const calculateExpiredLeverageValue = (ltv) => {
  let lev = 1 / (1 - ltv / 1e8);
  lev = Math.max(lev, 0);
  return lev;
};
const calculateExpiredPositionValue = (
  posToken,
  posCollateral,
  priceCollateral,
  priceToken
) => {
  let pnl = ((posToken + posCollateral) * priceCollateral) / priceToken;
  return pnl;
};
export const getExpiredPositionDetails = async (
  token,
  collateral,
  exp,
  ltv,
  balance,
  priceToken,
  priceCollateral
) => {
  const result = await crp().getPositionGivenBurnKey(
    `token-${token}`,
    `token-${collateral}`,
    exp * 1e8,
    balance * 1e8
  );
  const posCollateral = result['tupleValue']['dx'].value.toNumber() / 1e8;
  const posToken = result['tupleValue']['dy'].value.toNumber() / 1e8;
  let pnl = calculateExpiredPositionValue(
    posToken,
    posCollateral,
    priceCollateral,
    priceToken
  );
  let lev = calculateExpiredLeverageValue(ltv);
  return { posToken, posCollateral, pnl, lev };
};

export function toSFTYield(yTokenName) {
  const arr = yTokenName.split('-');
  const tokenName = arr.slice(0, 2).join('-');
  const expiry = Number(arr[2]);
  return { tokenName, expiry, yTokenName: tokenName };
}
export function toSFTKey(kTokenName) {
  const arr = kTokenName.split('-');
  const tokenName = `${arr[0]}-${arr[1]}-${arr[3]}`;
  const kExpiry = Number(arr[2]);
  return { kExpiry, kTokenName: tokenName };
}
export function toSFTYTP(ytpToken) {
  const arr = ytpToken.split('-');
  const ytpTokenName = `${arr[0]}-${arr[1]}-${arr[2]}`;
  const ytpExpiry = Number(arr[3]);
  return { ytpExpiry, ytpTokenName };
}
