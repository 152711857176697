let cached = {};

export const cacheData = (key, params, data) => {
  cached[key] = { data, params, update: false };
};
const compareObj = (objA, objB) =>{
  for (const key in objA) {
    if(objA[key] !== objB[key]){
      return false
    }
  }
  return true;
}
export const getCached = (_key, _params) => {
  // for (const key in cached) {
  //   const element = cached[key];
  //   if(key === _key){
  //     console.log('getCached##',key,element.params, _params);
  //   }
  //   if (key === _key && compareObj(element.params, _params)) {
  //     console.log('getCached hit',key, _key);
  //     return element;
  //   }
  // }
  return null;
};

export const updateAll = () => {
  for (const key in cached) {
    cached[key].update = true;
  }
};
export const updateOne = (key) => {
  cached[key].update = true;
};
