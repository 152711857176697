/* eslint-disable @next/next/no-sync-scripts */
import * as contractInfo from '../template/contract-info.json';
import 'antd/dist/antd.css';
import Layout from '../components/common/Layout';
import '../styles/globals.css';
import { AuthProvider } from '../context/auth-context';
import TXStatusWatcher from '../components/common/TXStatusWatcher';
import { AlexJS, MODES, NETWORK, STORAGE_TYPE } from '@alexgo-io/alex.js';
import { GlobalStatusProvider } from '../context/global-context';
global.alexjs = new AlexJS({
  mode: MODES.MOCK,
  network: NETWORK.MOCKNET,
  storageType: STORAGE_TYPE.LOCALSTORAGE,
  supportSFT: true,
  logReadOnly: false,
  templates: [...contractInfo.default.Contracts],
});
function MyApp({ Component, pageProps }) {
  return (
      <AuthProvider>
        <GlobalStatusProvider>
          <Layout>
            <TXStatusWatcher />
            <Component {...pageProps} />
          </Layout>
        </GlobalStatusProvider>
      </AuthProvider>
  );
}

export default MyApp;
